<template>
  
  <div>
      <v-main>
        <v-container fluid>
            <v-row class="mt-5">
                <v-col cols="12">
                    <v-card class="mx-auto" width="800">
                        <v-row>
                            <v-col cols="12" class="pl-5 pr-5 mb-n7">
                              <v-text-field
                                v-model="this.memberName"
                                label="Nama Anggota"
                                disabled
                              ></v-text-field>
                            </v-col>
                            <v-col cols="12" class="pl-5 pr-5 mb-n7">
                                <v-select
                                    v-model="form.depositeType"
                                    :items="listDeposite"
                                    label="Jenis Simpanan"
                                    item-text="simpananName"
                                    item-value="simpananName"
                                    multiple
                                    chips
                                    solo
                                    :error-messages="errMsgDeposite"
                                    required
                                ></v-select>
                            </v-col>
                            <template v-for="(type, i) in form.listDeposite" >
                                <v-col cols="12" class="pl-5 pr-5" :key="i" v-if="$root.lowercaseFormat(type.depositeType) === 'simpanan wajib' || type.depositeType === 'SW-01'">
                                    <v-card :key="i" 
                                      outlined
                                      tile
                                      align="left"
                                      class="pb-5">
                                        <v-card-text>
                                          <p class="subtitle-1 text--primary">
                                            Simpanan Wajib
                                          </p>
                                        </v-card-text>
                                        <v-row>
                                          <v-col cols="12" class="pl-5 pr-5">
                                              <v-menu
                                                ref="menu"
                                                v-model="menu"
                                                :close-on-content-click="false"
                                                :return-value.sync="periodeMandatory"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto"
                                              >
                                                <template v-slot:activator="{ on, attrs }">
                                                  <v-combobox
                                                    v-model="periodeMandatory"
                                                    multiple
                                                    chips
                                                    small-chips
                                                    label="Periode simpanan wajib"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    :error-messages="errMsgPeriode"
                                                    v-on="on"
                                                  ></v-combobox>
                                                </template>
                                                <v-date-picker
                                                  v-model="periodeMandatory"
                                                  multiple
                                                  :show-current="false"
                                                  type="month"
                                                >
                                                  <v-spacer></v-spacer>
                                                  <v-btn
                                                    text
                                                    color="primary"
                                                    @click="menu = false"
                                                  >
                                                    Cancel
                                                  </v-btn>
                                                  <v-btn
                                                    text
                                                    color="primary"
                                                    @click="addPeriode(periodeMandatory)"
                                                  >
                                                    OK
                                                  </v-btn>
                                                </v-date-picker>
                                              </v-menu>
                                          </v-col>
                                          <template v-for="(periode, i) in form.periodeMandatory">
                                            <v-col cols="12" class="pl-5 pr-5 mb-n5" :key="i">
                                                <v-text-field
                                                        :label="`Nominal periode ` + parseDatePeriode(periode.month)"
                                                        :hint="'Rp'+$root.currencyFormat(periode.amount) + (periode.amount !== '' ? ' (' + $root.terbilang(periode.amount) + ' rupiah)' : '')"
                                                        :error-messages="periode.amount.length === 0 ? 'Mohon masukan nominal ' + $root.lowercaseFormat(type.depositeType): (type.minAmount !== 0 && periode.amount < type.minAmount ) ? $root.lowercaseFormat(type.depositeType) + ' minimal sebesar Rp.'+type.minAmount : ''  "
                                                        v-model="periode.amount"
                                                        persistent-hint
                                                        required
                                                        solo
                                                        type="number"
                                                        :prefix="periode.amount.length === 0 ? '' : 'Rp'"
                                                      >
                                                </v-text-field>
                                            </v-col>
                                          </template>
                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" class="pl-5 pr-5 mb-n5" :key="i" v-else>
                                  <v-text-field
                                          :label="`Nominal ` + $root.lowercaseFormat(type.depositeType)"
                                          :hint="'Rp'+$root.currencyFormat(type.depositeAmount) + (type.depositeAmount !== '' ? ' (' + $root.terbilang(type.depositeAmount) + ' rupiah)' : '')"
                                          :error-messages="type.depositeAmount.length === 0 ? 'Mohon masukan nominal ' + $root.lowercaseFormat(type.depositeType): (type.minAmount !== 0 && type.depositeAmount < type.minAmount ) ? $root.lowercaseFormat(type.depositeType) + ' minimal sebesar Rp.'+type.minAmount : ''  "
                                          v-model="type.depositeAmount"
                                          persistent-hint
                                          required
                                          solo
                                          type="number"
                                          :prefix="type.depositeAmount.length === 0 ? '' : 'Rp'"
                                        >
                                  </v-text-field>
                              </v-col>
                            </template>
                            <v-col cols="12" class="pl-5 pr-5">
                                <v-select
                                  v-model="form.selectBank"
                                  :items="listBank"
                                  label="Pilih Metode Pembayaran"
                                  solo
                                  :error-messages="errPayMethod"
                                  required
                                >
                                    <template v-slot:selection="{ item }">
                                        <img :src="require('../../assets/bank/'+item.bankLogo)" width="100">
                                        <span>{{ item.bankName }}</span>
                                    </template>
                                    <template v-slot:item="{ item }">
                                        <img :src="require('../../assets/bank/'+item.bankLogo)" width="100">
                                        <span>{{ item.bankName }}</span>
                                    </template>
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" align="left" class="pl-5 pr-5">
                                <v-btn color="primary" :block=block @click="onSubmit">
                                  <span v-show="!isLoad">Topup</span>
                                  <b-spinner v-show="isLoad" small></b-spinner>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-snackbar
              v-model="snackbar"
              :timeout="timeout"
            >
              {{message}} 
            </v-snackbar>
        </v-container>
      </v-main>
  </div>
  
</template>

<script>

import {isEmpty} from '@/lib/util/utilities.js'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import listBank from '../../lib/json/list-bank.json'

export default {
  name: 'Savings',
  props: ['memberId', 'memberName'],
  mixins: [validationMixin],
    validations: {
        selectBank: {required},
        depositeAmount: {required},
        depositeType: {required}
  },
  components: {
  },
  data() {
    return {
        menu: false,
        snackbar: false,
        timeout: 3000,
        periodeMandatory: [],
        form: {
            selectBank: '',
            depositeAmount: '',
            depositeType: '',
            listDeposite: [],
            periodeMandatory: []
        },
        listDeposite: [],
        block: false,
        listBank: listBank.data,
        isLoad: false,
        message: null,
    }
  },
  computed: {
        errPayMethod() {
        const errors = []
            if (!this.$v.selectBank.$dirty) return errors
            if(this.form.selectBank.length==0) errors.push('Mohon pilih bank dahulu.')
            return errors
        },
        errMsgDeposite() {
        const errors = []
            if (!this.$v.depositeType.$dirty) return errors
            if(this.form.depositeType.length==0) errors.push('Mohon pilih jenis simpanan.')
            return errors
        },
        errMsgPeriode() {
        const errors = []
            if(this.periodeMandatory.length==0) errors.push('Mohon pilih periode simpanan.')
            return errors
        },
    },
  methods: {
    parseDatePeriode(date){
      var dateNum = parseInt(date.split('-')[1], 10)
      var year = date.substr(0, 4)
      switch(dateNum){
        case 1:
          return 'Januari ' + year
        case 2:
          return 'Februari ' + year
        case 3:
          return 'Maret ' + year
        case 4:
          return 'April ' + year
        case 5:
          return 'Mei ' + year
        case 6:
          return 'Juni ' + year
        case 7:
          return 'Juli ' + year
        case 8:
          return 'Agustus ' + year
        case 9:
          return 'September ' + year
        case 10:
          return 'Oktober ' + year
        case 11:
          return 'November ' + year
        case 12:
          return 'Desember ' + year
      }
    },
    addPeriode(periodeMandatory){
      this.$refs.menu[0].save(periodeMandatory)
      this.form.periodeMandatory = []
      for (let i = 0; i < periodeMandatory.length; i++) {
        this.form.periodeMandatory.push({
          month: periodeMandatory[i],
          amount: ''
        })
      }
    },
    reset() {
      this.form.selectBank = '',
      this.form.depositeAmount = '',
      this.form.depositeType = '',
      this.form.listDeposite = [],
      this.form.periodeMandatory = [],
      this.periodeMandatory = []
    },
    checkValidity() {
        this.$v.$touch()
        var isFill = false
        for (let i = 0; i < this.form.listDeposite.length; i++) {
          if (this.form.listDeposite[i].depositeType === 'Simpanan Wajib' || this.form.listDeposite[i].depositeType === 'SW-01') {
            var totalAmount = 0
            for (let a = 0; a < this.form.periodeMandatory.length; a++) {
              var amount = this.form.periodeMandatory[a].amount
              amount = parseInt(amount === '' ? '0' : amount, 10)
              if(amount.length === 0) {
                isFill = false
                break
              }
              else if (this.form.listDeposite[i].minAmount !== 0 && amount < this.form.listDeposite[i].minAmount  ) {
                isFill = false
                break
              }
              else {
                isFill = true 
                totalAmount += amount
              }
            }
            if (!isFill) break
            
            this.form.listDeposite[i].depositeAmount = totalAmount
          }
          else if(this.form.listDeposite[i].depositeAmount.length === 0) {
            isFill = false
            break
          }
          else if (this.form.listDeposite[i].minAmount !== 0 && this.form.listDeposite[i].depositeAmount < this.form.listDeposite[i].minAmount  ) {
            isFill = false
            break
          }
          else isFill = true
        }
        
        return !isEmpty(this.form.selectBank) && isFill && !isEmpty(this.form.depositeType)
    },
    async onSubmit() {
      this.isLoad = true
      if (!this.checkValidity()) {
        this.isLoad = false
          return
      }

      var items = [], tags = []
      for (let i = 0; i < this.form.listDeposite.length; i++) {
        for (let j = 0; j < this.listDeposite.length; j++) {
          if(this.form.listDeposite[i].depositeType === this.$root.capitalFirstChart(this.listDeposite[j].simpananName)){
            this.form.listDeposite[i].depositeType = this.listDeposite[j].simpananCode
            this.form.listDeposite[i].simpananId = this.listDeposite[j].simpananId
            continue
          }
        }

        if (this.form.listDeposite[i].depositeType === 'SW-01') {
          tags = this.form.periodeMandatory
        }else{
          tags = []
        }

        items.push({
          amount: this.form.listDeposite[i].depositeAmount,
          tag: tags,
          simpananId: this.form.listDeposite[i].simpananId
        })
      }

      await this.$root.api.sentAPI(
        {
          method: 'post',
          api: this.$root.api.post.topup,
          form: {
                bankName: this.form.selectBank.bankName.replace('Bank ', ''),
                items: items,
                memberId: this.memberId
            }
        })
        .then(res => {
          this.snackbar = true
          if(res.status.status === 'Success') {
            this.reset()
            this.message = res.status.message
          }
          else this.message = res.status.message
        })
        .catch((err) => {
            console.log(JSON.stringify(err))
        })
        this.isLoad = false
    }
  },
  watch: {
    'form.depositeType': function() {
      let newList = []
      
      for (let a = 0; a < this.form.depositeType.length; a++) {
        let amount = ''
        for (let b = 0; b < this.form.listDeposite.length; b++) {
            if(this.form.depositeType[a] === this.form.listDeposite[b].depositeType){
              amount = this.form.listDeposite[b].depositeAmount
              break
            }
        }
        newList.push({
            index: this.listDeposite.indexOf(this.listDeposite.find(x => x.simpananName === this.form.depositeType[a])),
            simpananId: null,
            depositeType: this.form.depositeType[a],
            depositeAmount: amount,
            minAmount: this.listDeposite.find(x => x.simpananName === this.form.depositeType[a]).minAmount
        })
      }
      this.form.listDeposite = newList
      
    }
  },
  created() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': 
      this.block = true
      break
      case 'sm': 
      this.block = false
      break
      case 'md': 
      this.block = false
      break
      case 'lg': 
      this.block = false
      break
      case 'xl': 
      this.block = false
      break
    }
  },
  async mounted() {
    await this.$root.api.sentAPI(
    {
      method: 'get',
      api: this.$root.api.get.simpanan
    }).then(res => {
        for (let i = 0; i < res.data.length; i++) {
          // if(res.data[i].simpananCode !== 'SP-01'){
              this.listDeposite.push({
              simpananId: res.data[i].simpananId,
              simpananCode: res.data[i].simpananCode,
              simpananName: this.$root.capitalFirstChart(res.data[i].simpananName),
              minAmount: res.data[i].simpananAmount.length === 1 ? res.data[i].simpananAmount[0] : 0
            })
          // }
        }
    })
    .catch((err) => {
      console.log(JSON.stringify(err))
    })
  },
}
</script>
<style scoped>

</style>